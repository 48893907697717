import { graphql, Link } from "gatsby";
import * as React from "react";
import { CONSTANTS } from "../components/constants";
import Image from "../components/image";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import Seo from "../components/seo";

export const conversationsPageQuery = graphql`
  query conversationsPageData($strapiIds: [Int]!) {
    conversations: allStrapiOkCarbonPosts(
      sort: { order: DESC, fields: published_at }
      filter: { strapiId: { in: $strapiIds } }
    ) {
      nodes {
        strapiId
        slug
        intervieweeName
        title
        intervieweeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 590
                quality: 90
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER, trim: 0 }
              )
            }
            extension
            publicURL
          }
        }
      }
      totalCount
    }
  }
`;

const Conversation = ({ pageContext, data }) => {
  return (
    <Layout altBg={true}>
      <Seo
        title="Conversations"
        // description= TODO: Conversations SEO description!
        meta={[
          {
            property: `og:url`,
            content: `${CONSTANTS.URL}/conversations/`,
          },
        ]}
      />
      <div className="section">
        <div className="row gtr-150 gtr-uniform">
          {data.conversations.nodes.map((convo) => (
            <div className="col-4 col-6-medium" key={convo.strapiId}>
              <span className="image fit">
                <Link to={`/${convo.slug}`} className="no-border">
                  {convo.intervieweeImage ? (
                    <Image image={convo.intervieweeImage} />
                  ) : (
                    //   No image place holder!
                    <>
                      <div
                        data-gatsby-image-wrapper=""
                        className="gatsby-image-wrapper"
                      >
                        <div
                          aria-hidden="true"
                          style={{
                            paddingTop: "100%",
                            backgroundColor: "lightskyblue",
                          }}
                        ></div>
                      </div>
                    </>
                  )}
                </Link>
              </span>
              <div className="item-title">
                <h4>{convo.title}</h4>
                <h3>{convo.intervieweeName}</h3>
              </div>
            </div>
          ))}
        </div>
        {pageContext.totalCount > 9 && (
          <div className="row aln-center xlarge-top-margin">
            <Pagination
              {...{
                url: `/conversations/page-`,
                totalCount: pageContext.totalCount,
                pageSize: 9,
                siblingCount: 1,
                currentPage: pageContext.page,
                firstPageSpecial: "/conversations/",
              }}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Conversation;
